import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavBar, Hero, Bio, Team, Reviews, StoreGallery, Pricing, Contact, Footer } from './components';
import { ContactPage, LinksPage } from './pages';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/socials" element={<LinksPage />} />
      </Routes>
      
      <div className="App">
        <NavBar />
        <Routes>
          <Route path='/' element={<>
            <Hero />
            <Bio />
            <Team />
            <Reviews />
            <StoreGallery />
            <Pricing />
            <Contact />
          </>}
          />
          <Route path="/contact" element={<ContactPage />} />
          {/* <Route path="/about" element={<AboutPage />} /> */}
          {/* <Route path="/services" element={<ServicesPage />} /> */}
        </Routes>
        <Footer />
      </div>
      <ToastContainer />
    </Router>
  );
}

export default App;
