import React from 'react'
import './contactPage.css'

function ContactPage() {
  return (
    <div className={`contactPage-container`}>
        ContactPage
    </div>
  )
}

export default ContactPage