import React from 'react'
import './hero.css'

const Hero = () => {
  return (
    <div className={`hero-container`}>
        <span className={`hero-title`}> S&J BarberShop</span>
        <div className={`hero-border`} >
        </div>
    </div>
  )
}

export default Hero