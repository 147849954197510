import { logo_black, share_icn, barber_link, link_to } from '../../imports.js'
import './LinksPage.css'
import '@fortawesome/fontawesome-free/css/all.min.css';



const LinksPage = () => {

    const handleShare = () => {
        const shareData = {
            title: 'SJ Barber',
            text: 'Check out SJ Barber social links!',
            url: 'https://sjbarber.com/socials'
        };


        if (navigator.share) {
            // Mobile: Use the native share dialog
            navigator.share(shareData).catch(error => console.log('Sharing failed:', error));
        } else {
            // Desktop: Copy link to clipboard
            navigator.clipboard.writeText(shareData.url)
                .then(() => alert('Link copied to clipboard!'))
                .catch(error => console.log('Copy to clipboard failed:', error));
        }
    };

    return (
        <div className={`links-container`}>
            <div className={`links-profile`}>
                <div className={`links-photos`}>
                    <img src={barber_link} alt='cover' className={`links-cover`} />
                    <img src={logo_black} alt='profile' className={`profile-photo`} />
                </div>
                <div className={`links-title`}>
                    <h3>SJ Barber</h3>
                    <p>Helping people gain back their confidence</p>
                    <p>Call us today 👇</p>
                    <p>(701) 200-8910</p>
                    <p>Visit our local shop</p>
                    <p>1410 9th St E Suite 505</p>
                    <p>W. Fargo, ND 58078</p>
                </div>
                <div  className={`links-share-btn`} onClick={handleShare}>
                    <img src={share_icn} alt='share'/>
                    <p> Share My Link </p>
                </div>
            </div>

            <div className={`links-buttons`}>
                <a href="https://www.facebook.com/sjbarbershopandbeauty" target="_blank" rel="noopener noreferrer" className={`link`}>
                    <div className={`link-icon-text`}>
                        <div class="fab fa-facebook"></div>
                        <p>Facebook</p>
                    </div>
                    <img src={link_to} alt='link' className={`go-to-icn`} />
                </a>
                <a href="https://www.instagram.com/sjbarbershopboutique" target="_blank" rel="noopener noreferrer" className={`link`}>
                    <div className={`link-icon-text`}>
                        <div class="fab fa-instagram"></div>
                        <p>Instagram</p>
                    </div>
                    <img src={link_to} alt='link' className={`go-to-icn`} />
                </a>
                <a href="https://www.tiktok.com/@sj.barbershop" target="_blank" rel="noopener noreferrer" className={`link`}>
                    <div className={`link-icon-text`}>
                        <div class="fab fa-tiktok"></div>
                        <p>TikTok</p>
                    </div>
                    <img src={link_to} alt='link' className={`go-to-icn`} />
                </a>
            </div>
        </div>
    )
}

export default LinksPage