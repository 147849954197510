import React, { useState, useEffect } from 'react';
import './reviews.css'

const mockReviews = [
  { id: 1, text: "Excellent service and friendly staff. Highly recommend!" },
  { id: 2, text: "Great experience, the staff was professional and very skilled." },
  { id: 3, text: "Loved the ambiance and the haircut was exactly what I wanted." },
];

const Reviews = () => {
  const [currentReviews, setCurrentReviews] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentReviews((currentReviews + 1) % mockReviews.length);
    }, 10000); // Change review every 10 seconds

    return () => clearInterval(intervalId);
  }, [currentReviews]);

  return (
    <section className={`reviews-container`}>
      <div className={`reviews-title`}>
        <h1>"</h1>
        <p>GREAT FEEDBACK FROM CUSTOMERS</p>
        <h2>Words of our happy customers</h2>
      </div>
      <div className="review">
        <p>“{mockReviews[currentReviews].text}”</p>
      </div>
      <div className={`razor`}></div>
      <div className={`reviews-border`}></div>
    </section>
  )
}

export default Reviews