import React from 'react';
import { Link } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './footer.css';

const Footer = () => {
    return (
        <footer>
            <div className="footer">
                <div className="footer-row">
                    <a href="https://www.facebook.com/sjbarbershopandbeauty" target="_blank" rel="noopener noreferrer">
                        <div class="fab fa-facebook"></div>
                    </a>
                    <a href="https://www.instagram.com/sjbarbershopboutique" target="_blank" rel="noopener noreferrer">
                        <div class="fab fa-instagram"></div>
                    </a>
                    <a href="https://www.tiktok.com/@sj.barbershop" target="_blank" rel="noopener noreferrer">
                        <div class="fab fa-tiktok"></div>
                    </a>
                </div>

                <div className="footer-row">
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/">Services</Link></li>
                        <li><Link to="/">Shop</Link></li>
                        <li><Link to="/">Book</Link></li>
                    </ul>
                </div>

                <div className="footer-row">
                    S&J Barbershop Copyright © 2024 S&J - All rights reserved || Designed By: ELKANA
                </div>
            </div>
        </footer>
    )
}

export default Footer