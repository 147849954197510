import React from 'react';
import { barber_shop, barber_shop_in, barber_client, barber_client_2, barber_tools } from '../../imports'
import './storeGallery.css'; 

const imageData = [ barber_shop, barber_shop_in, barber_client, barber_client_2, barber_tools ]

const StoreGallery = () => {
  return (
    <section className={`gallery-container`}>
        <div className={`gallery-title`}>
            <h2>Visit Our shop</h2>
            <p>Come And Unlock exclusive deals</p>
        </div>
        <div className={`border-1`}></div>

      {imageData.map((image, index) => (
        <div key={index} className={`gallery-item item-${index + 1}`}>
          <img src={image} alt={image.alt} />
        </div>
      ))}
    <div className={`border-2`}></div>
    </section>
  );
};

export default StoreGallery;
