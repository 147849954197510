import React from 'react'
import './team.css'
const Team = () => {
    return (
        <section className={`team-container`}>
            <div className={`team-border`} ></div>
            <div className="video-overlay"></div>
            <video autoPlay loop muted playsInline>
                <source src="https://res.cloudinary.com/ddtbiyfpj/video/upload/v1706769308/pexels-luciann-photography-11440852_2160p_jtdhmd.mp4" type="video/mp4" />
            </video>
            <div className={`team-content`}>
                <img src="https://res.cloudinary.com/ddtbiyfpj/image/upload/v1706770184/barber_portrait_by9n7c.jpg" alt="Barber Name" className={`barber-image`} />
                <h1>MAESTRO ALEX</h1>
                <p>Master Barber</p>
            </div>
            <div className={`team-border-rev`}></div>
        </section>
    )
}

export default Team