import React from 'react'
import { barber_badge } from '../../imports';
import './bio.css'

const Bio = () => {
  return (
    <div className={`bio-container`}>
        <div className={`bio-wrapper`}>
            <section className={`s-wrap-1`}>
                <img src={barber_badge} alt='badge'/>
                <h1> S&J BARBER & BEAUTY SALON </h1>
            </section>
            <section className={`s-wrap-2`}>
                <p> S&J Barber Shop grew out of the idea that there was no place for men to retreat. S&J are by far the last resort for men of all ages.  </p>
                <button> BOOK AN APPOINTMENT ↗ </button>
            </section>
        </div>
        <div style={{clear:'both'}}></div>
        <div className={`brush`}></div>
        <div style={{clear:'both'}}></div>
    </div>
  )
}

export default Bio