import React from 'react'
import './pricing.css'

const servicesData = [
  {
    title: "Cuts",
    types: [
      { name: "Men's Haircut", price: "$40", description: "High, mid, or low fade, or a traditional cut, we have you covered." },
      { name: "Women's Haircut", price: "$48", description: "A trim, layers added, or a whole new style, you're in good hands." },
      { name: "Kids' Haircut", price: "$33", description: "Customized kid's cut to fit their individual style and preferences." },
    ]
  },
  {
    title: "Locs",
    types: [
      { name: "Twist", price: "$203", description: "The comb twist technique is used when getting you started with dreads" },
      { name: "Repair", price: "$103", description: "Repairing dreads that are falling out, are loose, or you're wanting thickened." }
    ]
  },
  {
    title: "Braids",
    types: [
      { name: "Cornrows", price: "$48", description: "Pricing varies depending on time and complexity. Consultation needed." },
      { name: "Box Braids", price: "$203", description: "Pricing varies depending on time and complexity. Consultation needed." },
      { name: "Two Strand Braids", price: "$88", description: "Pricing varies depending on time and complexity. Consultation needed." }
    ]
  },
]

const Pricing = () => {
  return (
    <section className={`pricing-container`}>
      <div className={`main-title`}>
        <p>Your best local barber</p>
        <h2>
          Our Services
        </h2>
      </div>
      <div className={`services-grid`}>
        {servicesData.map((service, index) => (
          <div key={index} className={`service-category`}>
            <h2 className={`service-title`}>{service.title}</h2>
            <div className={`services-list`}>
              {service.types.map((type, idx) => (
                <div key={idx} className={`service-item`}>
                  <div className={`service-name-price`}>
                    <h3>{type.name}</h3>
                    <div className={`price-hyphen`}></div>
                    <p className={`price`}>{type.price}</p>
                  </div>
                  <p>{type.description}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <button className="more-services-btn">See More Services ↗ </button>
    </section>
  )
}

export default Pricing