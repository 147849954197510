import React, { useState, useEffect } from 'react';
import { nav_Logo } from '../../imports';
import './navbar.css';


const NavBar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
    }, [isOpen]);

    return (
        <nav className={`navigation-container`}>
            <div className={`logo`}>
                <img src={nav_Logo} alt='logo'/>
            </div>
            <div className={`hamburger`} onClick={toggleMenu}>
                {isOpen ? 'X' : '☰'}
            </div>

            {isOpen && (
                <div className={`menu ${isOpen ? 'open' : ''} `}>
                    <ul>
                        <li onClick={toggleMenu}>Home</li>
                        <li onClick={toggleMenu}>Services</li>
                        <li onClick={toggleMenu}>Shop</li>
                        <li onClick={toggleMenu}>Book</li>
                    </ul>
                </div>
            )}
        </nav>
    )
}

export default NavBar